<template>
  <div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleRead(record)">查看</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/QIDCreateForm'
import { commodity_qid_create, commodity_qid_list } from '@/api/qid'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      previewvisible: false,
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          align: 'center',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: 'QID',
          dataIndex: 'object_pk'
        },
        {
          title: '创建人',
          align: 'center',
          dataIndex: 'create_by'
        },
        {
          title: '是否售出',
          align: 'center',
          dataIndex: 'is_sell_out',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '是否打印',
          align: 'center',
          dataIndex: 'is_printed',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '创建时间',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '350px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return commodity_qid_list(Object.assign(parameter, this.queryParam, { exhibition_commodity_id: this.$route.params.id }))
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return data
          })
      }
    }
  },
  created () {},
  methods: {
    handleAdd () {
      this.mdl = {
        exhibition_commodity_id: this.$route.params.id
      }
      this.visible = true
    },
    handleRead (record) {
      console.log(record)
      this.$router.push('/exhibition/commodity/qid/record/' + record.object_pk)
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          commodity_qid_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    }
  }
}
</script>
